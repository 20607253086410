<template>
  <div>
    <div class="mb-4">
      <DefaultFilter :filterValue="currentFilter" @loadFilter="onLoadFilter" />
    </div>

    <div class="mb-4" style="float: right; max-width: 200px; margin-top: 35px">
      <FilterInput
        type="select2"
        :select2Options="years"
        :select2Settings="select2Settings"
        placeholder="Год"
        v-model="year"
        v-on:valueChangedDebounced="changeYear()"
      />
    </div>

    <tabs v-on:select="tabSelected" v-if="year > 0">
      <tab v-for="(tab, index) in titles" :key="index" :name="tab" :selected="index === 0">
        <div class="table-container mb-16 js-table-arrow" v-if="registryBag[index]">
          <table class="stockTable">
            <thead v-if="registryBag[index].filter">
              <tr>
                <th>Страна</th>
                <th v-for="(header, i) in headers[index]" :key="i">
                  {{ header }}
                </th>
              </tr>
              <tr>
                <th class="filter">
                  <FilterInput
                    type="select2"
                    :select2-options="countries"
                    :select2-settings="select2Settings"
                    v-model="registryBag[index].filter.countryId"
                    placeholder="(Все)"
                    v-on:valueChangedDebounced="changeFilter(index)"
                  />
                </th>
                <th class="filter">
                  <FilterInput
                    v-model="registryBag[index].filter.tnvedCode"
                    v-on:valueChangedDebounced="changeFilter(index)"
                  />
                </th>
                <th class="filter">
                  <FilterInput
                    v-model="registryBag[index].filter.tnvedName"
                    v-on:valueChangedDebounced="changeFilter(index)"
                  />
                </th>
                <th class="filter">
                  <div class="range">
                    <FilterInput
                      type="number"
                      v-model="registryBag[index].filter.valueFrom"
                      class="mr-2"
                      placeholder="от"
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                    <FilterInput
                      type="number"
                      v-model="registryBag[index].filter.valueTo"
                      placeholder="до"
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                  </div>
                </th>
                <th class="filter">
                  <div class="range">
                    <FilterInput
                      type="number"
                      v-model="registryBag[index].filter.tradeBalanceFrom"
                      class="mr-2"
                      placeholder="от"
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                    <FilterInput
                      type="number"
                      v-model="registryBag[index].filter.tradeBalanceTo"
                      placeholder="до"
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                  </div>
                </th>
                <th class="filter">
                  <div class="range">
                    <FilterInput
                      type="number"
                      v-model="registryBag[index].filter.annualValueGrowthFrom2016To2020From"
                      class="mr-2"
                      placeholder="от"
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                    <FilterInput
                      type="number"
                      v-model="registryBag[index].filter.annualValueGrowthFrom2016To2020To"
                      placeholder="до"
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                  </div>
                </th>
                <th class="filter">
                  <div class="range">
                    <FilterInput
                      type="number"
                      v-model="registryBag[index].filter.annualVolumeGrowthFrom2016To2020From"
                      class="mr-2"
                      placeholder="от"
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                    <FilterInput
                      type="number"
                      v-model="registryBag[index].filter.annualVolumeGrowthFrom2016To2020To"
                      placeholder="до"
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                  </div>
                </th>
                <th class="filter">
                  <div class="range">
                    <FilterInput
                      type="number"
                      v-model="registryBag[index].filter.annualValueGrowthFrom2019To2020From"
                      class="mr-2"
                      placeholder="от"
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                    <FilterInput
                      type="number"
                      v-model="registryBag[index].filter.annualValueGrowthFrom2019To2020To"
                      placeholder="до"
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                  </div>
                </th>
                <th class="filter">
                  <div class="range">
                    <FilterInput
                      type="number"
                      v-model="registryBag[index].filter.annualWorldGrowthFrom2016To2020From"
                      class="mr-2"
                      placeholder="от"
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                    <FilterInput
                      type="number"
                      v-model="registryBag[index].filter.annualWorldGrowthFrom2016To2020To"
                      placeholder="до"
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                  </div>
                </th>
                <th class="filter">
                  <div class="range">
                    <FilterInput
                      type="number"
                      v-model="registryBag[index].filter.countryShareWorldFrom"
                      class="mr-2"
                      placeholder="от"
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                    <FilterInput
                      type="number"
                      v-model="registryBag[index].filter.countryShareWorldTo"
                      placeholder="до"
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                  </div>
                </th>
                <th class="filter">
                  <div class="range">
                    <FilterInput
                      type="number"
                      v-model="registryBag[index].filter.rankingWorldFrom"
                      class="mr-2"
                      placeholder="от"
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                    <FilterInput
                      type="number"
                      v-model="registryBag[index].filter.rankingWorldTo"
                      placeholder="до"
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                  </div>
                </th>
                <th class="filter">
                  <div class="range">
                    <FilterInput
                      type="number"
                      v-model="registryBag[index].filter.avgDistanceToCountriesFrom"
                      class="mr-2"
                      placeholder="от"
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                    <FilterInput
                      type="number"
                      v-model="registryBag[index].filter.avgDistanceToCountriesTo"
                      placeholder="до"
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                  </div>
                </th>
                <th class="filter">
                  <div class="range">
                    <FilterInput
                      type="number"
                      v-model="registryBag[index].filter.countriesConcentrationFrom"
                      class="mr-2"
                      placeholder="от"
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                    <FilterInput
                      type="number"
                      v-model="registryBag[index].filter.countriesConcentrationTo"
                      placeholder="до"
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                  </div>
                </th>
                <th v-if="index === 1" class="filter">
                  <div class="range">
                    <FilterInput
                      type="number"
                      v-model="registryBag[index].filter.avgEstimatedRateFrom"
                      class="mr-2"
                      placeholder="от"
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                    <FilterInput
                      type="number"
                      v-model="registryBag[index].filter.avgEstimatedRateTo"
                      placeholder="до"
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody
              v-if="registryBag[index].page && registryBag[index].page?.items?.length && !registryBag[index].isLoading"
            >
              <tr v-for="item in registryBag[index].page.items" :key="item.id">
                <td>{{ item.country.nameFull || item.country.name }}</td>
                <td>{{ item.tnved.code }}</td>
                <td>{{ item.tnved.name }}</td>
                <td>{{ item.value }}</td>
                <td>{{ item.tradeBalance }}</td>
                <td>{{ item.annualValueGrowthFrom2016To2020 }}</td>
                <td>{{ item.annualVolumeGrowthFrom2016To2020 }}</td>
                <td>{{ item.annualValueGrowthFrom2019To2020 }}</td>
                <td>{{ item.annualWorldGrowthFrom2016To2020 }}</td>
                <td>{{ item.countryShareWorld }}</td>
                <td>{{ item.rankingWorld }}</td>
                <td>{{ item.avgDistanceToCountries }}</td>
                <td>{{ item.countriesConcentration }}</td>
                <td v-if="index === 1">{{ item.avgEstimatedRate }}</td>
              </tr>
            </tbody>
            <tbody v-else-if="registryBag[index].isLoading">
              <tr>
                <td class="text-center" colspan="13">Загрузка...</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td class="text-center" colspan="13">Нет данных.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="registryBag[index] && registryBag[index].page && registryBag[index].page.pagesTotal > 1">
          <Pagination
            :page-count="registryBag[index].page.pagesTotal"
            :current-page="registryBag[index].page.pageNumber"
            @change="onPageChange($event, false, index)"
            @more="onPageChange($event, true, index)"
          />
        </div>
      </tab>
    </tabs>
  </div>
</template>

<script>
  import DefaultFilter from '@/common/components/DefaultFilter';
  import tab from '@/common/components/tabsDetailInner.vue';
  import tabs from '@/common/components/tabsListInner.vue';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import Utils from '@/common/utils';

  import FilterInput from '../../../common/components/FilterInput';
  import Api from '../api/index';

  export default {
    name: 'TecWorldTable',
    components: { FilterInput, tabs, tab, DefaultFilter },
    props: {
      nn: Number,
    },
    mixins: [filtermanager],
    data() {
      return {
        route: 'tecworld',
        titles: ['Экспорт', 'Импорт'],
        registryBag: [],
        countries: [],
        years: [],
        year: null,
        select2Settings: Constants.select2Settings,
        headerTemplates: [
          [
            'Код',
            'Наименование товара',
            'Стоимость экспорта в xxx (тыс. $ США)',
            'Торговый баланс в xxx (тыс. $ США)',
            'Годовой прирост стоимости в период с xxx-4 по xxx (% в год)',
            'Годовой прирост объемов в период с xxx-4 по xxx (% в год)',
            'Годовой прирост стоимости в период с xxx-1 по xxx (% в год)',
            'Годовой прирост мирового импорта в период с xxx-4 по xxx (% в год)',
            'Мировой экспорт, доля страны (%)',
            'Рейтинг в мировом экспорте',
            'Среднее расстояние до импортирующих стран (км)',
            'Концентрация импортирующих стран',
          ],
          [
            'Код',
            'Наименование товара',
            'Стоимость импорта в xxx (тыс. $ США)',
            'Торговый баланс в xxx (тыс. $ США)',
            'Годовой прирост стоимости в период с xxx-4 по xxx (% в год)',
            'Годовой прирост объемов в период с xxx-4 по xxx (% в год)',
            'Годовой прирост стоимости в период с xxx-1 по xxx (% в год)',
            'Годовой прирост мирового экспорта в период с xxx-4 по xxx (% в год)',
            'Мировой импорт, доля страны (%)',
            'Рейтинг в мировом импорте',
            'Среднее расстояние до поставляющих стран (км)',
            'Концентрация поставляющих стран',
            'Средний тариф (расчетный) на применимый страны (%)',
          ],
        ],
      };
    },
    watch: {
      nn: function () {
        this.init();
      },
    },
    created() {
      this.init();
    },
    methods: {
      init() {
        this.loadFilter().then((response) => {
          let filters = response;
          this.loadDictionaries();
          this.registryBag = [];
          for (let type = 1; type <= this.titles.length; type++) {
            this.registryBag.push({
              page: {},
              request: {
                pageNumber: 1,
                pageSize: Constants.pageSize,
                tradingOperationId: type,
              },
              filter: (filters && filters[type - 1]) ?? {
                countryId: null,
                tnvedCode: null,
                tnvedName: null,
                valueFrom: null,
                valueTo: null,
                tradeBalanceFrom: null,
                tradeBalanceTo: null,
                annualValueGrowthFrom2016To2020From: null,
                annualValueGrowthFrom2016To2020To: null,
                annualVolumeGrowthFrom2016To2020From: null,
                annualVolumeGrowthFrom2016To2020To: null,
                annualValueGrowthFrom2019To2020From: null,
                annualValueGrowthFrom2019To2020To: null,
                annualWorldGrowthFrom2016To2020From: null,
                annualWorldGrowthFrom2016To2020To: null,
                countryShareWorldFrom: null,
                countryShareWorldTo: null,
                rankingWorldFrom: null,
                rankingWorldTo: null,
                avgDistanceToCountriesFrom: null,
                avgDistanceToCountriesTo: null,
                countriesConcentrationFrom: null,
                countriesConcentrationTo: null,
                avgEstimatedRateFrom: null,
                avgEstimatedRateTo: null,
              },
              isLoading: false,
            });
          }
          console.log(this.registryBag);
          if (this.registryBag[0].filter?.year) this.year = this.registryBag[0].filter.year;

          this.loadPage(0);
        });
      },
      loadPage(index, append) {
        this.registryBag[index].isLoading = true;
        const request = Object.assign(this.registryBag[index].filter, this.registryBag[index].request);
        Api.search(this.route, request)
          .then((response) => {
            if (!append) {
              this.registryBag[index].page = {};
              this.registryBag[index].page = response.data;
            } else if (response.data) {
              this.registryBag[index].page.pageNumber = response.data.pageNumber;
              this.registryBag[index].page.items = this.registryBag[index].page.items.concat(response.data.items);
            }
            this.registryBag[index].isLoading = false;
          })
          .catch((error) => {
            this.registryBag[index].isLoading = false;
            console.error(error?.response?.data);
          });
      },
      loadDictionaries() {
        Utils.searchNsi('countrylistwithdata', {})
          .then((response) => {
            this.countries = response.data.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
        Api.getTecWorldYears().then((response) => {
          this.years = response.data.map((x) => {
            return { text: x, id: x };
          });
          //if(this.years.length > 0 && !this.year)
          //  this.year = this.years[this.years.length - 1].id;
        });
      },
      onPageChange(pageNumber, addToExist, index) {
        this.registryBag[index].request.pageNumber = pageNumber;
        this.loadPage(index, addToExist);
      },
      tabSelected(index) {
        this.currentTab = index;
        if (!this.registryBag[index].page?.items?.length) {
          this.loadPage(index);
        }
      },
      changeFilter(index) {
        this.registryBag[index].request.pageNumber = 1;
        this.saveFilter(this.currentFilter);
        this.loadPage(index, false);
      },
      changeYear() {
        this.registryBag[0].filter.year = this.year;
        this.registryBag[1].filter.year = this.year;
        this.changeFilter(this.currentTab == 1 ? 1 : 0);
      },
      onLoadFilter(item) {
        for (let type = 0; type < this.titles.length; type++) {
          this.registryBag[type].filter = (item && item[type - 1]) ?? {
            countryId: null,
            tnvedCode: null,
            tnvedName: null,
            valueFrom: null,
            valueTo: null,
            tradeBalanceFrom: null,
            tradeBalanceTo: null,
            annualValueGrowthFrom2016To2020From: null,
            annualValueGrowthFrom2016To2020To: null,
            annualVolumeGrowthFrom2016To2020From: null,
            annualVolumeGrowthFrom2016To2020To: null,
            annualValueGrowthFrom2019To2020From: null,
            annualValueGrowthFrom2019To2020To: null,
            annualWorldGrowthFrom2016To2020From: null,
            annualWorldGrowthFrom2016To2020To: null,
            countryShareWorldFrom: null,
            countryShareWorldTo: null,
            rankingWorldFrom: null,
            rankingWorldTo: null,
            avgDistanceToCountriesFrom: null,
            avgDistanceToCountriesTo: null,
            countriesConcentrationFrom: null,
            countriesConcentrationTo: null,
            avgEstimatedRateFrom: null,
            avgEstimatedRateTo: null,
          };
        }
        this.changeFilter();
      },
    },
    computed: {
      currentFilter() {
        let filters = [];
        for (let i = 0; i < this.registryBag.length; i++) {
          filters.push(this.registryBag[i].filter);
        }
        return filters;
      },
      headers() {
        var result = [];
        for (var i = 0; i < 2; i++) {
          var res = this.headerTemplates[i];
          var year = this.year;
          res = res.map(function (e) {
            e = e
              .replace('xxx-4', year - 4)
              .replace('xxx-1', year - 1)
              .replace('xxx', year);
            return e;
          });
          result.push(res);
        }
        return result;
      },
    },
  };
</script>

<style lang="scss">
  .filter {
    .range {
      display: flex;

      .input-group-wrapper {
        input {
          padding: 4px 8px;
          min-width: 80px;
          max-width: 80px;
        }
      }
    }
  }
</style>
